import React from "react";
import { View, TouchableOpacity, Text } from "react-native";
import SelectMonth from "../filter/SelectMonth";
import { CommonReload } from "../filter/CommonReload";
import FreeSelectMonth from "../filter/FreeSelectMonth";

export function HeaderHome(props: any) {
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <FreeSelectMonth />
      <CommonReload />
    </View>
  );
}
