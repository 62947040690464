import * as React from "react";
import { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Text,
  FlatList,
  ActivityIndicator,
} from "react-native";

import { useFocusEffect, useNavigation } from "@react-navigation/native";
import {
  OrgFilterType,
  OrgOption,
  allCompanyOption,
  departmentOption,
} from "../../components/filter/CommonBumonFilter";
import { LocalStorageKey } from "../../models/LocalStorage";

import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Spinner,
  Card,
} from "react-bootstrap";
import HomeCountTable from "../../components/home/HomeCountTable";
import { HomeTotal, HomeTotalModel } from "../../models/HomeModel";
import HomeTargetTable from "../../components/home/HomeTargetTable";
import { currentYM } from "../../util/DateUtil";
import { UsersGoal, UsersGoalsModel } from "../../models/UsersGoalsModel";
import { InvoiceModel } from "../../models/InvoiceModel";
import SelectOrgFilter from "../../components/filter/SelectOrgFilter";
import NotificationToast from "../../components/common/NotificationToast";
import { Regex } from "../../constants/Regex";
import { unsecuredCopyToClipboard } from "../../util/CommonUtil";
import { UserModel } from "../../models/UserModel";
import YearMonthLabel from "../../components/common/YearMonth";
import { useAuth0 } from "@auth0/auth0-react";
import {
  loadSelectedOptionByCompany,
  orgOptionsToParam,
} from "../../util/OrgUtil";
import CoverIndicator from "../../components/common/CoverIndicator";
import HomeTargetModal from "../../components/home/HomeTargetModal";
import { Organisations } from "../../models/OrganisationsModel";
import { AssignmentType } from "../../models/AssignmentModel";
import { APP_VERSION_DATA } from "../../constants/Setting";
import { get } from "../../util/Api";
import { MaterialCommunityIcons } from "@expo/vector-icons";

export default function HomeScreen(props: any) {
  /**navigation*/
  const navigation = useNavigation();

  // const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  // const [userMetadata, setUserMetadata] = useState(null);

  const selectedMyOrganisationsHome = loadSelectedOptionByCompany(
    LocalStorageKey.HOME
  );

  /**一覧関連****************************************/
  /**帳票*/
  const [homeTotals, setHomeTotals] = useState<{ [key: string]: HomeTotal[] }>({
    [currentYM()]: [],
  });

  /**目標集計関連******************************************************** */
  /**受領合計*/
  const [total, setTotal] = useState<{ [key: string]: number }>({
    [currentYM()]: 0,
  });
  /**確認合計*/
  const [total_k, setTotal_k] = useState<{ [key: string]: number }>({
    [currentYM()]: 0,
  });
  /**目標(参考)*/
  const [oldGoal, setOldGoal] = useState<{ [key: string]: number }>({
    [currentYM()]: 0,
  });
  /**目標(自分)*/
  const [goal, setGoal] = useState<{ [key: string]: string }>({
    [currentYM()]: "",
  });
  /**目標(自分) オブジェクト*/
  const [usersGoals, setUsersGoals] = useState<{
    [key: string]: UsersGoal[] | undefined;
  }>({});
  /**全部門 */
  const [allOrgs, setAllOrgs] = useState<Organisations[]>([]);

  /**画面制御関連****************************************** */
  /**目標登録ボタンのインジケーター用 */
  const [isUpdatingGoal, setIsUpdatingGoal] = useState<boolean>(false);
  /**目標取得中のインジケーター用 */
  const [isFetchingGoal, setIsFetchingGoal] = useState<{
    [key: string]: boolean;
  }>({
    [currentYM()]: true,
  });

  /**読み込みフラグ*/
  const [isLoading, setIsLoading] = useState<{
    [key: string]: boolean;
  }>({
    [currentYM()]: true,
  });
  /**初回読み込みフラグ*/
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  /**目標値モーダル表示フラグ */
  const [showTargetModal, setShowTargetModal] = useState(false);

  /*　未受領数 */
  const [notReceivedCount, setNotReceivedCount] = useState<
    number | undefined
  >();

  /**フィルタ制御関連****************************************/
  /**選択年月*/
  const [selected_YM, setSelected_YM] = useState<string>(currentYM());

  const [fromOrgOptions, setFromOrgOptions] = useState<OrgOption[]>(
    selectedMyOrganisationsHome?.fromOrgOptions ?? [departmentOption]
  );
  const [toOrgOptions, setToOrgOptions] = useState<OrgOption[]>(
    selectedMyOrganisationsHome?.toOrgOptions ?? [allCompanyOption]
  );

  /**
   * 一覧を取得する
   * @param billing_ym
   */
  async function _getList(billing_ym: string = selected_YM, fromOrg?: number) {
    setIsLoading((pre) => ({ ...pre, [selected_YM]: true }));
    setHomeTotals((pre) => ({ ...pre, [selected_YM]: [] }));

    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);

    try {
      const totals = await HomeTotalModel.fetchList({
        billing_ym: billing_ym,
        from_filter_types: fromOrgParams.filter_types,
        from_org_ids: fromOrgParams.org_ids,
        to_filter_types: toOrgParams.filter_types,
        to_org_ids: toOrgParams.org_ids,
        // to_self_org_ids: [],
        // from_self_org_ids: [],
      });
      setHomeTotals((pre) => ({ ...pre, [selected_YM]: totals }));
      setIsLoading((pre) => ({ ...pre, [selected_YM]: false }));
      setIsFirstLoad(false);
    } catch (error) {
      window.functions.logout;
    }
  }

  /**
   * 目標値を取得する
   */
  async function fetchGoal(ym: string, isInit: boolean = true) {
    setIsFetchingGoal((pre) => ({ ...pre, [selected_YM]: true }));
    if (isInit) {
      setGoal((pre) => ({ ...pre, [selected_YM]: "" }));
      setUsersGoals((pre) => ({ ...pre, [selected_YM]: [] }));

      setTotal((pre) => ({
        ...pre,
        [selected_YM]: 0,
      }));
      setTotal_k((pre) => ({
        ...pre,
        [selected_YM]: 0,
      }));
    }

    //自分自身の目標値を取得する。
    const usersGoalPromise = UsersGoalsModel.all({ yyyymm: ym });
    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);

    //請求の合計値を取得する。
    const invoicePromise = InvoiceModel.getTotalByMonth({
      from_filter_types: fromOrgParams.filter_types,
      from_org_ids: fromOrgParams.org_ids,
      to_filter_types: toOrgParams.filter_types,
      to_org_ids: toOrgParams.org_ids,
      billing_ym: ym,
    });
    //参考目標値を取得する。
    const oldGoalPromise = UsersGoalsModel.oldGoal({
      from_filter_types: fromOrgParams.filter_types,
      from_org_ids: fromOrgParams.org_ids,
      billing_ym: ym,
    });
    // 両方の非同期処理が完了するまで待機
    const [usersGoals, invoice, oldGoal] = await Promise.all([
      usersGoalPromise,
      invoicePromise,
      oldGoalPromise,
    ]);

    setTotal((pre) => ({
      ...pre,
      [selected_YM]: invoice?.total ?? 0,
    }));
    setTotal_k((pre) => ({
      ...pre,
      [selected_YM]: invoice?.total_k ?? 0,
    }));
    setOldGoal((pre) => ({ ...pre, [selected_YM]: oldGoal ?? 0 }));
    // if (usersGoal?.goal === undefined) {
    //   setIsFetchingGoal((pre) => ({ ...pre, [selected_YM]: false }));
    //   return;
    // }

    const formatUserGoals = usersGoals?.map((e) => {
      e.goal = e.goal / 1000;
      return e;
    });

    // goalsの合計値を求める
    const totalGoals = formatUserGoals?.reduce((sum, e) => sum + e.goal, 0);

    // let usersGoalThousand = (usersGoal.goal ?? 0) / 1000;
    setUsersGoals((pre) => ({ ...pre, [selected_YM]: formatUserGoals }));
    setGoal((pre) => ({
      ...pre,
      [selected_YM]: totalGoals ? totalGoals.toString() : "",
    }));
    setIsFetchingGoal((pre) => ({ ...pre, [selected_YM]: false }));
  }

  /**
   * 目標値を登録する
   */
  async function upSertGoals(usersGoals: UsersGoal[]) {
    setIsUpdatingGoal(true);

    const usersGoalsAdd1000 = usersGoals.map((e) => {
      e.goal = e.goal * 1000;
      return e;
    });

    await UsersGoalsModel.upsert(usersGoalsAdd1000);
    await fetchGoal(selected_YM, false);
    setIsUpdatingGoal(false);
    NotificationToast().updated();
  }

  /**
   * 目標(参考)をクリップボードにコピーする
   */
  const handleCopy = async () => {
    if (oldGoal[selected_YM] !== undefined) {
      unsecuredCopyToClipboard(oldGoal[selected_YM].toString());
      NotificationToast().success("クリップボードにコピーしました");
    }
  };

  /**グローバル関数を設定する */
  const setWindowFunctions = () => {
    //月選択ボタン
    window.functions.setMonthFilter = (ym: string) => {
      setSelected_YM(ym);
    };

    //画面フォーカス時
    if (window.reloadFlag) {
      window.reloadFlag = false;
      _getList();
      fetchNotReceivedCount();
      fetchGoal(selected_YM, true);
    }

    //リロードボタン
    window.functions.reloadList = () => {
      _getList();
      fetchGoal(selected_YM, true);
    };
  };

  const openTargetModal = () => {
    setShowTargetModal(true);
  };

  const closeModal = () => {
    setShowTargetModal(false);
  };

  const targertRegister = (v: UsersGoal[]) => {
    closeModal();
    upSertGoals(v);
  };

  const onFetchedAllOrgs = (v: Organisations[]) => {
    setAllOrgs(v);
  };

  /**選択された部門の目標値を取得する。 */
  const getSeletedOrgGoals = () => {
    const all = fromOrgOptions.some((e) => e.type.id == OrgFilterType.ALL.id);
    const self = fromOrgOptions.some((e) => e.type.id == OrgFilterType.SELF.id);
    const receive = fromOrgOptions.some(
      (e) => e.type.id == OrgFilterType.RECEIVE.id
    );

    const thousand = 1;

    const selectedYMusersGoals = usersGoals[selected_YM] ?? [];

    //【全社】か【自分の所属部門】を選択している場合。
    if (all || self) {
      // goalsの合計値を求める

      const sumGoal = selectedYMusersGoals?.reduce((sum, e) => sum + e.goal, 0);
      return sumGoal * thousand;
    }
    //【自分の受領権限】を選択している場合。
    let selectedMyOrg: Organisations[] = [];
    let selectedOrgIds: number[] = [];
    if (receive) {
      selectedMyOrg = allOrgs.filter(
        (e) => e.assignment?.role === AssignmentType.RECEIVING_AUTHORITY
      );
      selectedOrgIds = selectedMyOrg.map((e) => e.id);
    }

    //【選択部門】
    const otherSelectOrgIds = fromOrgOptions.map((e) => e.organisations?.id);
    const ids = otherSelectOrgIds.filter(
      (id): id is number => typeof id === "number"
    );
    //結合して重複を除去する
    selectedOrgIds = Array.from(new Set([...ids, ...selectedOrgIds]));

    //セグメントで選択している所属部門
    const selectedGoals = selectedYMusersGoals?.filter((e) =>
      selectedOrgIds.includes(e.from_org)
    );

    // goalsの合計値を求める
    const sumGoal = selectedGoals.reduce((sum, e) => sum + e.goal, 0);

    return sumGoal;
  };

  // アプリのバージョンチェック
  async function app_version_check() {
    const url = "/app_version_check";
    let response = await get(url, { app_version: APP_VERSION_DATA() });
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    if (response.data) {
      alert(
        "バージョンが古い可能性がございます。ブラウザのキャッシュをクリアしてください。"
      );
    }
  }

  const fetchNotReceivedCount = async () => {
    setNotReceivedCount(undefined);
    try {
      const res = await InvoiceModel.fetchRetrieveUnreceivedAmount();
      setNotReceivedCount(res);
    } catch (error) {
      setNotReceivedCount(0);
    }
  };

  /************************************************
   * useEffect
   * *********************************************
   */
  /**請求元・請求先フィルタータイプの変更 */
  useEffect(() => {
    // if (isFirstLoad) return;
    _getList();
    fetchGoal(selected_YM);
  }, [toOrgOptions, fromOrgOptions]);

  // `selected_YM`の値が変更された時にリストを更新する
  useEffect(() => {
    fetchGoal(selected_YM);
    _getList();
  }, [selected_YM]);

  // 抽出条件が変更されたらリスナーを更新する。
  useFocusEffect(() => {
    setWindowFunctions();
  });

  // 画面切り替え時にバージョンが違っていたらアラート表示する。
  useEffect(() => {
    app_version_check();
    fetchNotReceivedCount();
  }, [props.route.name]);

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          width: "100%",
          paddingHorizontal: 10,
        }}
      >
        <YearMonthLabel ym={selected_YM} />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 5,
            alignSelf: "flex-end",
          }}
        >
          <MaterialCommunityIcons name="bell-ring" size={16} color="grey" />
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={{ marginLeft: 5 }}>未受領件数：</Text>
            {notReceivedCount === undefined ? (
              <ActivityIndicator
                size="small"
                color="grey"
                style={{ marginLeft: 5 }}
              />
            ) : (
              <>{notReceivedCount}</>
            )}
          </View>
        </View>
        <SelectOrgFilter
          localStorageKey={LocalStorageKey.HOME}
          defaultFrom={fromOrgOptions}
          onChangeFrom={setFromOrgOptions}
          defaultTo={toOrgOptions}
          onChangeTo={setToOrgOptions}
          onFetchedAllOrgs={onFetchedAllOrgs}
        />

        <Card style={{ marginBottom: 10 }}> </Card>
        <Row style={{ marginTop: 5 }}>
          <Col sm="12" md="6">
            <Form>
              <Form.Group
                as={Row}
                // className="mb-3."
                controlId="formHorizontalEmail"
              >
                <Form.Label column sm={2}>
                  目標合計:
                </Form.Label>
                <Col>
                  <InputGroup size="sm" className="mb-3">
                    <Form.Control
                      type="number"
                      value={getSeletedOrgGoals() ?? ""} // goalがnullまたはundefinedの場合は空文字列を設定
                      placeholder="設定されていません"
                      aria-label="目標金額を入力"
                      aria-describedby="basic-addon2"
                      style={{ textAlign: "right" }}
                      // onChange={(e) => {
                      //   const value = e.target.value;
                      //   setGoal((pre) => ({ ...pre, [selected_YM]: value }));
                      // }}
                      disabled={true}
                    />
                    <InputGroup.Text>千円</InputGroup.Text>
                    <Button
                      variant="outline-primary"
                      id="button-addon2"
                      onClick={openTargetModal}
                      disabled={isUpdatingGoal}
                    >
                      {isUpdatingGoal ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "設定"
                      )}
                    </Button>
                  </InputGroup>
                </Col>
              </Form.Group>
            </Form>
          </Col>
          <Col sm="12" md="6">
            <Form>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  目標(参考):
                </Form.Label>
                <Col>
                  <InputGroup size="sm" className="mb-3">
                    <Form.Control
                      value={oldGoal[selected_YM] ?? ""}
                      type="number"
                      readOnly={true}
                      disabled={true}
                      placeholder="目標参考値を表示"
                      aria-label="目標参考値を表示"
                      aria-describedby="basic-addon2"
                      style={{ textAlign: "right" }}
                    />
                    <InputGroup.Text>千円</InputGroup.Text>
                    {/* <Button
                      variant="outline-primary"
                      id="button-addon2"
                      onClick={handleCopy}
                    >
                      コピー
                    </Button> */}
                  </InputGroup>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <HomeTargetTable
          isLoading={isFetchingGoal[selected_YM]}
          total={total[selected_YM]}
          total_k={total_k[selected_YM]}
          usersGoals={usersGoals[selected_YM] ?? []}
          allOrgs={allOrgs}
          fromOrgOptions={fromOrgOptions}
        />
        <Card style={{ marginBottom: 10 }}> </Card>
        <div style={{ width: "100%", overflow: "auto" }}>
          <HomeCountTable homeTotals={homeTotals[selected_YM] ?? []} />
        </div>
      </ScrollView>
      {!isFetchingGoal[selected_YM] && (
        <HomeTargetModal
          show={showTargetModal}
          onClose={closeModal}
          onRegister={targertRegister}
          fromOrgOptions={fromOrgOptions}
          allOrgs={allOrgs}
          preUsersGoals={usersGoals[selected_YM] ?? []}
          selectYM={selected_YM}
        ></HomeTargetModal>
      )}
      {isLoading[selected_YM] && <CoverIndicator />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
  },
  td: {
    borderWidth: 1,
    borderColor: "#ccc",
    backgroundColor: "#ccc",
  },
});
