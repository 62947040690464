import * as React from "react";

import { CheckBox, Text } from "react-native-elements";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { Container, Form } from "react-bootstrap";
import { CommonColor } from "../../constants/Colors";
import { useState } from "react";
import { Regex } from "../../constants/Regex";
import { DailyTeiban, quantity } from "../../models/DailyTeibanModel";
import { CommonButton } from "../common/CommonButton";
import FontAwesome from "@expo/vector-icons/build/FontAwesome";

type DailyTeibanListItemProps = {
  item: DailyTeiban;
  work?: string;
  isChecked: boolean;
  quantity: string;
  onCheck?: (id: string) => void;
  onChangeQuantity: (quantity: quantity) => void;
  onPress?: () => void;
  onTapWork: (v: DailyTeiban) => void;
  onUpdated?: (v: DailyTeiban) => void;
};

/**
 * 定番一覧のアイテム
 * @param param0
 * @returns
 */
const DailyTeibanListItem = React.forwardRef(
  (props: DailyTeibanListItemProps, ref) => {
    /**設定数量 */
    const [quantity, setQuantity] = useState<string>(props.quantity);
    // const [work, setQuantity] = useState<string>(props.quantity);

    // /**設定数量を変更した。 */
    // const isEditedQuantity = quantity !== "";

    /**
     * 定番タップイベント
     */
    const handleOnPress = () => {
      if (props.onPress) {
        props.onPress();
      }
    };

    //設定数量を変更する
    const handleOnChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e === undefined) return;
      const value = e.target.value;
      if (value.length > 2) return;
      if (Regex.NUMBER.test(value) || value === "") {
        setQuantity(value);
      }
    };

    const handleOnTapWork = () => {
      props.onTapWork(props.item);
    };

    const handleOnTextInputTap = () => {
      // テキスト入力のタップ時に発火するイベントハンドラー
      // 何も処理を行わず、タップイベントの伝播を停止する
    };

    // Enterキーが押されたときのイベントハンドラ
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault(); // デフォルトのフォームの移動を防ぐ
        event.target.blur(); // フォーカスを外す
        props.onChangeQuantity({
          id: props.item.id,
          quantity: Number(quantity),
        });
      }
    };

    // 設定数量のフォーカスが外れたときのイベントハンドラ
    const handleOnBlur = async () => {
      props.onChangeQuantity({ id: props.item.id, quantity: Number(quantity) });
    };

    const renderTitle = () => {
      return (
        <View style={{ width: "60%" }}>
          <View
            style={{
              flexDirection: "column",
              flex: 1,
              justifyContent: "center", // 垂直方向に中央揃え
            }}
          >
            <Text style={[styles.subtitle]}>{props.item.to_org_name}</Text>
            {renderWork()}
            <Text style={[styles.total]}>{props.item.unit_price}</Text>
          </View>
        </View>
      );
    };

    const renderWork = () => {
      const workName = props.work ?? "未設定";
      const color = props.work ? CommonColor.Black : CommonColor.Normal.Blue;

      return (
        <TouchableOpacity
          onPress={handleOnTapWork}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text style={[styles.work_name, { color: color }]}>{workName}</Text>
          <FontAwesome
            style={{ paddingHorizontal: 3 }}
            name={"edit"}
            size={13}
            color={CommonColor.Normal.Blue}
          />
        </TouchableOpacity>
      );
    };

    const renderQuantity = () => {
      return (
        <View style={{ width: "40%", padding: 3 }}>
          <TouchableOpacity onPress={handleOnTextInputTap}>
            <Text style={{ fontSize: 12 }}>数量設定</Text>
            <Form.Control
              value={quantity ?? ""}
              type="number"
              placeholder="未設定"
              onChange={handleOnChangeQuantity}
              maxLength={2}
              onKeyDown={handleKeyDown}
              onBlur={handleOnBlur}
            />
          </TouchableOpacity>
        </View>
      );
    };

    const renderEditButton = () => {
      return (
        <View
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            flex: 1,
            height: "100%",
          }}
        >
          <CommonButton
            icon="edit"
            iconStyle={{ color: CommonColor.Normal.Blue }}
            onPress={handleOnPress}
          ></CommonButton>
        </View>
      );
    };

    return (
      <Container fluid className="p-0 border">
        <View style={{ flexDirection: "row", flexShrink: 1 }}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: 40,
            }}
          >
            <CheckBox
              containerStyle={{
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
              wrapperStyle={{
                margin: 0,
              }}
              checked={props.isChecked}
              checkedColor={CommonColor.Normal.Red}
              onPress={() => {
                if (props.onCheck === undefined) {
                  return;
                }
                props.onCheck(props.item.id.toString());
              }}
            />
          </View>
          <View style={{ flexDirection: "row", width: "75%" }}>
            {renderTitle()}
            {renderQuantity()}
          </View>
          <View style={{ flex: 1, alignItems: "flex-end" }}>
            {renderEditButton()}
          </View>
        </View>
      </Container>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  content: {
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 12,
    color: "#222",
  },

  /**仕事名 */
  work_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#222",
  },

  /**金額 */
  total: {
    fontSize: 14,
    color: "#F00",
  },
  /**矢印 */
  allow: {
    paddingLeft: 3,
  },
  checkboxContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
  },
});

export default DailyTeibanListItem;
