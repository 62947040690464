import * as React from "react";
import { useState, useEffect, useRef } from "react";
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import {
  OrgOption,
  allCompanyOption,
  departmentOption,
} from "../../components/filter/CommonBumonFilter";
import { Row } from "react-bootstrap";

import { InvoiceStateType } from "../../models/InvoiceModel";
import { currentYM } from "../../util/DateUtil";
import {
  WorkListFilter,
  WorkListFilterModel,
} from "../../models/WorkListFilterModel";
import WorkListFilterGroup from "../../components/filter/WorkListFilterGroup";
import {
  WorkListCompare,
  WorkListCompareModel,
} from "../../models/WorkListCompareModel";
import WorkCompareListTable from "../../components/workList/WorkCompareListTable";
import { useFocusEffect } from "@react-navigation/native";
import YearMonthLabel from "../../components/common/YearMonth";
import { FloatingButton } from "../../components/common/FloatingButton";
import CoverIndicator from "../../components/common/CoverIndicator";
import SelectOrgFilter from "../../components/filter/SelectOrgFilter";
import { LocalStorageKey } from "../../models/LocalStorage";
import { Organisations } from "../../models/OrganisationsModel";
import { WorkType } from "../../models/WorkTypeModel";
import {
  loadSelectedOptionByCompany,
  orgOptionsToParam,
} from "../../util/OrgUtil";
import { CommonButton } from "../../components/common/CommonButton";
import { numberFormat } from "../../util/Math";
import { CheckBox } from "react-native-elements";
import NotificationToast from "../../components/common/NotificationToast";
import { APP_VERSION_DATA } from "../../constants/Setting";
import { get } from "../../util/Api";
/**
 * Compares screen
 * @param props
 * @returns
 */
export default function CompareScreen(props: any) {
  const [monthPage, setMonthPage] = useState<{ [key: string]: number }>({
    [currentYM()]: 0,
  });
  const [monthlast_page, setMonthLastPage] = useState<{
    [key: string]: number;
  }>({
    [currentYM()]: -1,
  });
  const [monthCompares, setMonthCompares] = useState<{
    [key: string]: WorkListCompare[];
  }>({});

  const selectedMyOrganisations = loadSelectedOptionByCompany(
    LocalStorageKey.WORK_COMPARE
  );

  /**画面制御関連********************************************/
  /**仕事一覧読み込み中フラグ */
  const [isLoading, setIsLoading] = useState(true);
  /**フィルタ読み込み中フラグ */
  const [isFilterLoading, setIsFilterLoading] = useState(true);
  /**TOPスクロールするボタンの表示フラグ */
  const [isShowUpButton, setIsShowUpButton] = useState(false);
  /**画面全体を覆うインジケーターの表示フラグ */
  const [isShowCoverIndicator, setIsShowCoverIndicator] = useState(true);
  /** スクロールビューを参照するためのref */
  const scrollViewRef = useRef<ScrollView>(null);
  /** スクロールビューの高さを設定するための状態変数 */
  const [scrollViewHeight, setScrollViewHeight] = useState<number>(0);
  /** テーブル要素を参照するためのref */
  const tableRef = useRef<HTMLTableElement>(null);
  /** テーブルの高さを設定するための状態変数*/
  const [tableHeight, setTableHeight] = useState<number>(0);
  /**初回読み込みフラグ */
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  /**フィルタ制御関連****************************************/
  const [fromOrgOptions, setFromOrgOptions] = useState<OrgOption[]>(
    selectedMyOrganisations.fromOrgOptions ?? [departmentOption]
  );
  const [toOrgOptions, setToOrgOptions] = useState<OrgOption[]>(
    selectedMyOrganisations.toOrgOptions ?? [allCompanyOption]
  );
  /**年月の指定 */
  const [selected_YM, setSelected_YM] = useState<string>(currentYM());

  /**請求先の有効化フラグ */
  const [isEnabledToOrg, setIsEnabledToOrg] = useState(false);
  /**仕事(大)の有効化フラグ */
  const [isEnabledWorkTypeLarge, setIsEnabledWorkTypeLarge] = useState(false);
  /**仕事(中)の有効化フラグ */
  const [isEnabledWorkTypeMid, setIsEnabledWorkTypeMid] = useState(false);
  /**仕事(小)の有効化フラグ */
  const [isEnabledWorkTypeSmall, setIsEnabledWorkTypeSmall] = useState(false);
  /**請求先フィルタ */
  const [toOrgFilter, setToOrgFilter] = useState<Organisations | undefined>();
  /**仕事(大)フィルタ */
  const [workTypeLargeFilter, setWorkTypeLargeFilter] = useState<
    WorkType | undefined
  >();
  /**仕事(中)フィルタ */
  const [workTypeMidFilter, setWorkTypeMidFilter] = useState<
    WorkType | undefined
  >();
  /**仕事(小)フィルタ */
  const [workTypeSmallFilter, setWorkTypeSmallFilter] = useState<
    WorkType | undefined
  >();
  /**フィルタ情報 */
  const [monthFilters, setMonthFilters] = useState<{
    [key: string]: WorkListFilter;
  }>({});

  //絞り込み条件：受領のみ
  const [isOnlyReceived, setIsOnlyReceived] = useState<boolean>(false);
  //絞り込み条件：指定年月に存在する請求のみ
  const [isOnlySelectedYM, setIsOnlySelectedYM] = useState<boolean>(false);

  /**指定されている月の読み込みページ */
  const currentMonthPage = monthPage[selected_YM];
  /**指定されている月の最終ページ */
  const currentMonthLastPage = monthlast_page[selected_YM];
  /**フィルタのパラメータ */
  const filter = {
    isEnabledToOrg: isEnabledToOrg,
    isEnabledWorkTypeLarge: isEnabledWorkTypeLarge,
    isEnabledWorkTypeMid: isEnabledWorkTypeMid,
    isEnabledWorkTypeSmall: isEnabledWorkTypeSmall,
    toOrgFilterId: toOrgFilter?.id,
    workTypeLargeFilterId: workTypeLargeFilter?.id,
    workTypeMidFilterId: workTypeMidFilter?.id,
    workTypeSmallFilterId: workTypeSmallFilter?.id,
    toOrgFilter: toOrgFilter,
    workTypeLargeFilter: workTypeLargeFilter,
    workTypeMidFilter: workTypeMidFilter,
    workTypeSmallFilter: workTypeSmallFilter,
  };

  /**データ取得 */
  const loadNext = async (params?: {
    /**初期化する　*/
    isInit?: boolean;
    /**年月　*/
    selected_YM?: string;
  }) => {
    const isInit = params?.isInit ?? false;
    if (isInit === false) {
      //初回取得ではない場合
      if (
        currentMonthPage === currentMonthLastPage &&
        currentMonthPage &&
        currentMonthLastPage
      ) {
        //最終ページまで取得した場合
        return;
      }
    }
    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);

    setIsLoading(true);
    //比較一覧を取得する
    try {
      const res = await WorkListCompareModel.fetchList({
        page: isInit ? 1 : currentMonthPage + 1,
        billing_ym: params?.selected_YM ?? selected_YM,
        from_filter_types: fromOrgParams.filter_types,
        from_org_ids: fromOrgParams.org_ids,
        to_filter_types: toOrgParams.filter_types,
        to_org_ids: toOrgParams.org_ids,
        states: getStateType(),
        isOnlySelectedYM: isOnlySelectedYM ? 1 : 0,
        filter: filter,
      });

      if (params?.isInit ?? false) {
        //初回取得の場合は集計も取得する
        const total = await WorkListCompareModel.fetchTotal({
          billing_ym: params?.selected_YM ?? selected_YM,
          from_filter_types: fromOrgParams.filter_types,
          from_org_ids: fromOrgParams.org_ids,
          to_filter_types: toOrgParams.filter_types,
          to_org_ids: toOrgParams.org_ids,
          states: getStateType(),
          isOnlySelectedYM: isOnlySelectedYM ? 1 : 0,
          filter: filter,
        });

        //一行目に集計行を入れる
        const initList = [total].concat(res.data);

        setMonthCompares((prevMonthInvoices) => ({
          ...prevMonthInvoices,
          [selected_YM]: initList,
        }));
      } else {
        let currentInvoices = monthCompares[selected_YM] ?? [];
        let tmp = currentInvoices.slice();

        tmp = tmp.concat(res.data);

        setMonthCompares((prevMonthReceipts) => ({
          ...prevMonthReceipts,
          [selected_YM]: tmp,
        }));
      }
      setMonthPage((pre) => ({
        ...pre,
        [selected_YM]: res.current_page,
      }));
      setMonthLastPage((pre) => ({
        ...pre,
        [selected_YM]: res.last_page,
      }));

      setIsLoading(false);
      setIsShowCoverIndicator(false);
    } catch (error) {
      NotificationToast().error(
        "集計出来ませんでした。条件を絞り込んで下さい。"
      );
      setIsLoading(false);
      setIsShowCoverIndicator(false);
    }
  };

  /**請求状態を取得 */
  const getStateType = () => {
    //請求状態の指定
    if (isOnlyReceived) {
      return [InvoiceStateType.Received];
    }
    return [
      InvoiceStateType.Received,
      InvoiceStateType.Pending,
      InvoiceStateType.Onhold,
      InvoiceStateType.Resubmitted,
    ];
  };

  /**リセット処理 */
  const onReload = async () => {
    setIsShowCoverIndicator(true);
    setMonthCompares((prevMonthInvoices) => ({
      ...prevMonthInvoices,
      [selected_YM]: [],
    }));
    setMonthPage((pre) => ({
      ...pre,
      [selected_YM]: 0,
    }));
    setMonthLastPage((pre) => ({
      ...pre,
      [selected_YM]: -1,
    }));
    loadFilter();
    await loadNext({ isInit: true });
    setIsShowCoverIndicator(false);
    setIsFirstLoad(false);
  };

  /**フィルタを読み込む */
  const loadFilter = async (params?: { isFirst?: boolean }) => {
    setIsFilterLoading(true);

    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);

    const res = await WorkListFilterModel.fetchFilter({
      page: currentMonthPage + 1,
      billing_ym: selected_YM,
      from_filter_types: fromOrgParams.filter_types,
      from_org_ids: fromOrgParams.org_ids,
      to_filter_types: toOrgParams.filter_types,
      to_org_ids: toOrgParams.org_ids,
      states: getStateType(),
      isOnlySelectedYM: isOnlySelectedYM ? 1 : 0,
      filter: filter,
    });

    setMonthFilters((pre) => ({
      ...pre,
      [selected_YM]: res,
    }));
    setIsFilterLoading(false);
  };

  /**
   * スクロールイベント
   */
  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    /**操作対象の要素を取得 */
    const nativeEvent = event.nativeEvent;

    if (
      nativeEvent.contentOffset.y + nativeEvent.layoutMeasurement.height >=
      nativeEvent.contentSize.height - 1
    ) {
      if (isLoading) return;
      loadNext();
    }
    /**テーブル下まで500pxを切ったら、一番上へスクロールするボタンを表示 */
    nativeEvent.contentOffset.y > 500
      ? setIsShowUpButton(true)
      : setIsShowUpButton(false);
  };

  /**
   * フィルタを初期化する
   */
  const initFilter = () => {
    setIsEnabledToOrg(false);
    setIsEnabledWorkTypeLarge(false);
    setIsEnabledWorkTypeMid(false);
    setIsEnabledWorkTypeSmall(false);
    setToOrgFilter(undefined);
    setWorkTypeLargeFilter(undefined);
    setWorkTypeMidFilter(undefined);
    setWorkTypeSmallFilter(undefined);
  };

  /**
   * 展開用の一覧を取得する
   * @param e
   * @returns
   */
  const onExpand = async (
    e: WorkListCompare,
    type: "large" | "middle" | "small" | "work"
  ) => {
    const workTypeId = e.work_type_id;
    if (workTypeId == null) return;

    const fromOrgParams = orgOptionsToParam(fromOrgOptions);
    const toOrgParams = orgOptionsToParam(toOrgOptions);
    const compareList = monthCompares[selected_YM];

    // state変数を、setterを介さずに編集
    e.isLoading = true;
    const loadingList = compareList;

    setMonthCompares((prevMonthReceipts) => ({
      ...prevMonthReceipts,
      [selected_YM]: loadingList,
    }));

    let res: {
      current_page?: number;
      last_page?: number;
      total?: number;
      data: WorkListCompare[];
    };
    if (type == "large") {
      // 大分類配下の中分類を取得（work_type_idを指定すると、中分類以下を取得するようになる）
      res = await WorkListCompareModel.fetchList({
        page: 0, // 中分類の場合使わない
        billing_ym: selected_YM,
        to_filter_types: toOrgParams.filter_types,
        to_org_ids: toOrgParams.org_ids,
        from_filter_types: fromOrgParams.filter_types,
        from_org_ids: fromOrgParams.org_ids,
        states: getStateType(),
        filter: filter,
        isOnlySelectedYM: isOnlySelectedYM ? 1 : 0,
        work_type_id: workTypeId,
      });
    } else {
      //} if( type=="middle" ){
      // 中分類配下の小分類以下を取得
      res = await WorkListCompareModel.fetchWorkList({
        billing_ym: selected_YM,
        from_filter_types: fromOrgParams.filter_types,
        from_org_ids: fromOrgParams.org_ids,
        to_filter_types: toOrgParams.filter_types,
        to_org_ids: toOrgParams.org_ids,
        states: getStateType(),
        isOnlySelectedYM: isOnlySelectedYM ? 1 : 0,
        filter: filter,
        work_type_id: workTypeId,
      });
    }

    // state変数を、setterを介さずに編集
    e.work_list = res.data;
    e.isLoading = false;
    const detailList = compareList;

    setMonthCompares((prevMonthReceipts) => ({
      ...prevMonthReceipts,
      [selected_YM]: detailList,
    }));
  };

  /**グローバル関数を設定する */
  const setWindowFunctions = () => {
    window.functions.reloadList = () => {
      if (isShowCoverIndicator) return;
      onReload();
    };

    if (window.reloadFlag) {
      window.reloadFlag = false;
      onReload();
    }

    window.functions.setMonthFilter = async (ym: string) => {
      setSelected_YM(ym);
    };
  };

  // アプリのバージョンチェック
  async function app_version_check() {
    const url = "/app_version_check";
    let response = await get(url, { app_version: APP_VERSION_DATA() });
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    if (response.data) {
      alert(
        "バージョンが古い可能性がございます。ブラウザのキャッシュをクリアしてください。"
      );
    }
  }

  /**useEffect------------------------------------------------------ */

  /**抽出条件が変更されたらグローバル関数を更新する */
  useFocusEffect(() => {
    setWindowFunctions();
  });

  /**■フィルタ有無 */
  useEffect(() => {
    if (isFirstLoad) return;
    onReload();
  }, [
    toOrgOptions,
    fromOrgOptions,
    isEnabledToOrg,
    isEnabledWorkTypeLarge,
    isEnabledWorkTypeMid,
    isEnabledWorkTypeSmall,
  ]);
  /**■仕事(小)フィルタ */
  useEffect(() => {
    if (isFirstLoad) return;
    if (isEnabledWorkTypeSmall) {
      onReload();
    }
  }, [workTypeSmallFilter]);
  /**■仕事(中)フィルタ */
  useEffect(() => {
    if (isFirstLoad) return;
    if (isEnabledWorkTypeMid) {
      onReload();
    }
  }, [workTypeMidFilter]);
  /**■仕事(大)フィルタ */
  useEffect(() => {
    if (isFirstLoad) return;
    if (isEnabledWorkTypeLarge) {
      onReload();
    }
  }, [workTypeLargeFilter]);
  /**■請求先フィルタ */
  useEffect(() => {
    if (isFirstLoad) return;
    if (isEnabledToOrg) {
      onReload();
    }
  }, [toOrgFilter]);

  /**■確認中のみフィルタ・指定月のみフィルタ */
  useEffect(() => {
    if (isFirstLoad) return;
    initFilter();
    onReload();
  }, [isOnlyReceived, isOnlySelectedYM]);

  /**■年月 */
  useEffect(() => {
    initFilter();
    onReload();
  }, [selected_YM]);
  /**■ページ */
  useEffect(() => {
    if (isFirstLoad) return;
    if (tableRef === null) return;
    if (tableRef.current === null || tableRef.current === undefined) return;
    if (scrollViewRef.current === null || scrollViewRef.current === undefined)
      return;
    setTableHeight(tableRef.current.getBoundingClientRect().height);

    //ページが読み込めていない場合は処理をしない。
    if (
      currentMonthLastPage === undefined ||
      currentMonthLastPage === -1 ||
      currentMonthPage === undefined
    )
      return;
    //最終ページまで読み込んでいる場合は処理をしない。
    if (currentMonthLastPage === currentMonthPage) return;
    if (tableHeight < scrollViewHeight) {
      loadNext();
    }
  }, [currentMonthLastPage, currentMonthLastPage]);

  // 画面切り替え時にバージョンが違っていたらアラート表示する。
  useEffect(() => {
    app_version_check();
  }, [props.route.name]);

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          width: "100%",
          paddingHorizontal: 10,
          overflow: "scroll",
        }}
        onScroll={onScroll}
        scrollEventThrottle={0}
        ref={scrollViewRef}
        onLayout={(event) => {
          let { height } = event.nativeEvent.layout;
          setScrollViewHeight(height);
        }}
      >
        <YearMonthLabel ym={selected_YM} />
        <div style={{ padding: 5 }}>
          <SelectOrgFilter
            localStorageKey={LocalStorageKey.WORK_COMPARE}
            defaultFrom={fromOrgOptions}
            onChangeFrom={setFromOrgOptions}
            defaultTo={toOrgOptions}
            onChangeTo={setToOrgOptions}
          />
          <Row>
            <WorkListFilterGroup
              isLoading={isFilterLoading}
              filter={monthFilters[selected_YM]}
              selectedFilter={filter}
              onChangedToOrg={setIsEnabledToOrg}
              onChangedWorkTypeLarge={setIsEnabledWorkTypeLarge}
              onChangedWorkTypeMid={setIsEnabledWorkTypeMid}
              onChangedWorkTypeSmall={setIsEnabledWorkTypeSmall}
              onSelectedToOrg={setToOrgFilter}
              onSelectedWorkTypeLarge={setWorkTypeLargeFilter}
              onSelectedWorkTypeMid={setWorkTypeMidFilter}
              onSelectedWorkTypeSmall={setWorkTypeSmallFilter}
            />
          </Row>
        </div>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckBox
            containerStyle={{ paddingHorizontal: 10, paddingVertical: 1 }}
            checked={isOnlyReceived}
            onPress={() => {
              setIsOnlyReceived(!isOnlyReceived);
            }}
            title="受領のみ"
          />
          <CheckBox
            containerStyle={{ paddingHorizontal: 10, paddingVertical: 1 }}
            checked={isOnlySelectedYM}
            onPress={() => {
              setIsOnlySelectedYM(!isOnlySelectedYM);
            }}
            title="指定月に存在する請求のみ"
          />
        </View>
        <WorkCompareListTable
          selected_YM={selected_YM}
          items={monthCompares[selected_YM] ?? []}
          isLoading={isLoading && !isShowCoverIndicator}
          tableRef={tableRef}
          onExpand={onExpand}
        />
      </ScrollView>
      {isShowUpButton && (
        <FloatingButton
          icon="arrow-up"
          onPress={() => {
            if (
              scrollViewRef.current === undefined ||
              scrollViewRef.current === null
            )
              return;
            scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
          }}
        />
      )}
      {isShowCoverIndicator && <CoverIndicator />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
