import { post } from "../util/Api";
import { WorkType } from "./WorkTypeModel";

export interface WorkMemoRequests {
  id?: number;
  created_at?: Date | null;
  updated_at?: Date | null;
  company_id: number;
  work_type_id?: number;
  work_memo_id?: string;
  user_id: number;
  comment?: string;
}

export class WorkMemoRequestsModel {
  /**
   * 引数のデータからWorkMemoRequestsオブジェクトを生成して返します。
   *
   * @param data 生成元のデータ
   * @returns WorkMemoRequestsオブジェクト
   */
  static set(data: any): WorkMemoRequests {
    // WorkMemoRequestsオブジェクトを生成
    const d: WorkMemoRequests = {
      // 必須フィールドの設定
      id: data.id,
      company_id: data.company_id,
      user_id: data.user_id,

      // 任意フィールドの設定
      work_type_id: data?.work_type_id ?? null,
      work_memo_id: data?.work_memo_id ?? null,
      created_at: data?.created_at ?? null,
      updated_at: data?.updated_at ?? null,
      comment: data?.comment ?? "",
    };

    return d;
  }

  static async find(params: {
    workMemoId?: number;
    workTypeId?: number;
  }): Promise<WorkMemoRequests | null> {
    const uri = "/workmemos/request/find";
    let response = await post(uri, params);
    console.log(response?.data);
    if (response?.data?.id == undefined) {
      return null;
    }
    return this.set(response.data);
  }

  static async upsert(params: {
    id?: number;
    workMemoId?: number;
    workTypeId?: number;
    comment: string;
  }): Promise<WorkMemoRequests> {
    const uri = "/workmemos/request/upsert";
    let response = await post(uri, params);
    return this.set(response.data);
  }

  static async delete(params: { id: number }): Promise<WorkMemoRequests> {
    const uri = "/workmemos/request/delete";
    let response = await post(uri, params);
    return this.set(response.data);
  }
}
