import * as React from "react";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Calendar from "react-calendar";
import { View, Text, StyleSheet } from "react-native";

interface BillingDateProps {
  billingDate: string;
  dt: Date;
  onSelectCalendar?: (tm: any) => void;
}

/**
 * 請求日を設定するコンポーネント
 */
const BillingDate = (props: BillingDateProps) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const openCalendar = () => {
    setShowCalendar(true);
  };
  const closeCalendar = () => {
    setShowCalendar(false);
  };

  const handleSelected = (tm: any) => {
    closeCalendar();
    if (props?.onSelectCalendar == undefined) return;
    props.onSelectCalendar(tm);
  };

  return (
    <>
      <View style={styles.itemViewStyle}>
        <Text style={{}}>請求日</Text>
        <Text style={styles.itemStyleEdit} onPress={openCalendar}>
          {props.billingDate}
          <View style={{ position: "absolute", right: 0 }}>
            <Text style={{ fontWeight: "normal" }}>＞</Text>
          </View>
        </Text>
        {/* <DatePicker
                value={propsdt}
                isOpen={isPickerOpen}
                onSelect={handleSelect}
                onCancel={handleCancel}
                confirmText={"選択"}
                cancelText={"閉じる"}
              /> */}
      </View>

      <Modal show={showCalendar} onHide={closeCalendar} size="sm" centered>
        <Modal.Body>
          <Calendar
            value={props.dt}
            calendarType="gregory"
            onClickDay={handleSelected}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  itemViewStyle: {
    marginBottom: 12,
  },
  itemStyle: {
    fontWeight: "bold",
    fontSize: 17,
  },
  itemStyleEdit: {
    fontWeight: "bold",
    color: "#333",
    fontSize: 17,
  },
});
export default BillingDate;
