import { post } from "../util/Api";
import { WorkListFilterOption } from "./WorkListFilterModel";

/**
 *　仕事比較一覧フィルタ
 */
export interface WorkListCompare {
  isLoading: boolean;
  /**中分類仕事の名称 */
  workTypeMidName: string | null;
  /**小分類仕事の名称 */
  workTypeSmallName: string | null;
  /** 仕事の名称 */
  name: string | null;
  /**現在の金額 */
  tsc: number;
  /**1か月前の金額 */
  t1ma: number;
  /**2か月前の金額 */
  t2ma: number;
  /**3か月前の金額 */
  t3ma: number;
  /**1年前の金額 */
  t1ya: number;
  /** 仕事詳細リスト */
  work_list: WorkListCompare[];

  work_type_id: number | null;
}

export class WorkListCompareModel {
  static set(data: any): WorkListCompare {
    const d: WorkListCompare = {
      isLoading: false,
      workTypeMidName: data.workTypeMidName ?? "",
      workTypeSmallName: data.workTypeSmallName ?? "",
      name: data.name,
      work_type_id: data.work_type_id,
      tsc: data.total_sum_current,
      t1ma: data.total_sum_1_month_ago,
      t2ma: data.total_sum_2_months_ago,
      t3ma: data.total_sum_3_months_ago,
      t1ya: data.total_sum_1_year_ago,
      work_list: data.work_list
        ? data.work_list.map((wl: any) => this.set(wl))
        : [],
    };

    return d;
  }

  /**
   * 仕事比較一覧を取得する
   */
  static async fetchList(params: {
    page: number;
    billing_ym: string;
    to_filter_types: number[];
    to_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];
    states: number[];
    filter: WorkListFilterOption;
    isOnlySelectedYM: number;
    work_type_id: number;
  }): Promise<{
    current_page: number;
    last_page: number;
    total: number;
    data: WorkListCompare[];
  }> {
    const uri = "/compare/list";
    const response = await post(uri, params);

    if (response.status !== 200) {
      throw new Error();
    }

    const responseData = response.data;
    const receipts = Array.isArray(responseData.data)
      ? responseData.data.map((data: any) => this.set(data))
      : [this.set(responseData.data)];

    return {
      current_page: responseData.current_page,
      last_page: responseData.last_page,
      data: receipts,
      total: responseData.total,
    };
  }

  /**
   * 仕事比較一覧を取得する
   */
  static async fetchWorkList(params: {
    billing_ym: string;
    to_filter_types: number[];
    to_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];
    states: number[];
    filter: WorkListFilterOption;
    isOnlySelectedYM: number;
    work_type_id: number;
  }): Promise<{
    data: WorkListCompare[];
  }> {
    const uri = "/compare/workList";
    const response = await post(uri, params);

    if (response.status !== 200) {
      throw new Error();
    }

    const responseData = response.data;
    const receipts = Array.isArray(responseData)
      ? responseData.map((data: any) => this.set(data))
      : [this.set(responseData)];

    return {
      data: receipts,
    };
  }

  /**
   * 仕事比較の集計を取得する
   */
  static async fetchTotal(params: {
    billing_ym: string;
    to_filter_types: number[];
    to_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];
    states: number[];
    filter: WorkListFilterOption;
    isOnlySelectedYM: number;
  }): Promise<WorkListCompare> {
    const uri = "/compare/total";
    const response = await post(uri, params);

    if (response.status !== 200) {
      throw new Error();
    }

    const responseData = response.data;

    return {
      isLoading: false,
      /**中分類仕事の名称 */
      workTypeMidName: null,
      /**小分類仕事の名称 */
      workTypeSmallName: null,
      /** 仕事の名称 */
      name: "合計",
      work_type_id: null,
      /**現在の金額 */
      tsc: responseData?.total_sum_current ?? 0,
      t1ma: responseData?.total_sum_1_month_ago ?? 0,
      t2ma: responseData?.total_sum_2_months_ago ?? 0,
      t3ma: responseData?.total_sum_3_months_ago ?? 0,
      t1ya: responseData?.total_sum_1_year_ago ?? 0,
      /** 仕事詳細リスト */
      work_list: [],
    };
  }

  // *** 使っていないのでコメントアウト ***
  // /**
  //  * 仕事比較一覧を取得する
  //  * @param params
  //  * @returns mid history
  //  * @deprecated
  //  */
  // static async fetchCompareList(params: {
  //   page: number;
  //   billing_ym: string;
  //   to_filter_types: number[];
  //   to_org_ids: number[];
  //   from_filter_types: number[];
  //   from_org_ids: number[];
  //   states: number[];
  //   filter: WorkListFilterOption;
  // }): Promise<{
  //   current_page: number;
  //   last_page: number;
  //   total: number;
  //   data: WorkListCompare[];
  // }> {
  //   const uri = "/workList/compareList";
  //   let response = await post(uri, params);

  //   if (response.status !== 200) {
  //     throw new Error();
  //   }

  //   const responseData = response.data;
  //   const receipts = Array.isArray(responseData.data)
  //     ? responseData.data.map((data: any) => this.set(data))
  //     : [this.set(responseData.data)];

  //   const data = {
  //     current_page: responseData.current_page,
  //     last_page: responseData.last_page,
  //     data: receipts,
  //     total: responseData.total,
  //   };

  //   return data;
  // }
}
