import * as React from "react";
import { useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { HeaderTitleBar } from "../../components/common/HeaderBar";
import TeibanListItem from "../../components/teiban/TeibanListItem";
import { FloatingButton } from "../../components/common/FloatingButton";
import { Teiban, TeibanModel } from "../../models/TeibanModel";
import { SCREENS } from "../../constants/Screens";
import { CommonColor } from "../../constants/Colors";
import CommonModal from "../../components/modal/CommonModal";
import { Col, Row } from "react-bootstrap";
import NotificationToast from "../../components/common/NotificationToast";
import CoverIndicator from "../../components/common/CoverIndicator";
import CommonLoadingIndicator from "../../components/common/LoadingIndicator";
import { isLoaded } from "expo-font";
import {
  currentYM,
  decrementMonth,
  displayToYearMonth,
  incrementYM,
} from "../../util/DateUtil";
import { CommonButton } from "../../components/common/CommonButton";
import FreeSelectMonth from "../../components/filter/FreeSelectMonth";

export default function TeibanScreen(props: any) {
  const selectedYM: string = props.route.params.selected_YM ?? "";

  /**navigation */
  const navigation = useNavigation();

  /** 定番一覧関連 ****************************************************************/
  /**定番一覧 */
  const [teibans, setTeibans] = useState<Teiban[]>([]);
  /**定番チェック */
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});

  /** 画面制御関連 ****************************************************************/
  /**定番下部の読み込みフラグ */
  const [isBottomLoading, setIsBottomLoading] = useState(false);
  /**操作不可の読み込みフラグ */
  const [isLoading, setIsLoading] = useState(true);
  /**定番読み込みページ */
  const [page, setPage] = useState(0);
  /**定番読み込み最終ページ */
  const [last_page, setLastPage] = useState(-1);
  /**削除モーダルの表示フラグ */
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  /**一括定番コピーモーダルの表示フラグ */
  const [isShowCopyAllModal, setIsShowCopyAllModal] = useState<boolean>(false);
  /**前月請求コピーモーダルの表示フラグ */
  const [isShowCopyLastMonthModal, setIsShowCopyLastMonthModal] =
    useState<boolean>(false);

  /**年月*/
  const [selected_YM, setSelected_YM] = useState<string>(selectedYM);
  /**前月 */
  const lastYM = decrementMonth(selected_YM);

  /**チェック済みの項目の有無 */
  const hasChecked = Object.values(checkedItems).includes(true);
  /**チェック済みの項目のid */
  const checkedIds = Object.keys(checkedItems)
    .filter((key) => checkedItems[key])
    .map(Number);

  /**
   * 定番一覧を読み込む
   * @param pageNo
   * @param lastPageNo
   * @returns
   */
  async function loadNext(pageNo?: number, lastPageNo?: number) {
    pageNo = pageNo ?? page;
    lastPageNo = lastPageNo ?? last_page;
    if (pageNo === lastPageNo) return;
    if (isBottomLoading) return;
    setIsBottomLoading(true);
    let params: { page: number; type: number; filter?: any } = {
      page: pageNo + 1,
      type: props.route.params.type,
    };
    try {
      const res = await TeibanModel.index(params);
      setTeibans((prevDataArray) => [...prevDataArray, ...res.data]);
      setPage(res.current_page);
      setLastPage(res.last_page);
      setIsBottomLoading(false);
      setIsLoading(false);
    } catch (error) {
      window.functions.logout();
    }
  }
  /**
   * 定番の☑変更
   * @returns
   */
  const handleCheckboxToggle = (itemId: string) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  /**
   * 定番の☑を外す
   * @returns
   */
  const handleUncheckAll = () => {
    setCheckedItems({});
  };

  /**
   * 全ての定番に☑をつける
   * @returns
   */
  const handleCheckAllItems = () => {
    const updatedCheckedItems: Record<string, boolean> = {};
    for (const item of teibans) {
      updatedCheckedItems[item.id] = true;
    }
    setCheckedItems(updatedCheckedItems);
  };

  /**
   * 定番を削除する
   */
  const deleteTeiban = async () => {
    setIsShowDeleteModal(false);
    setIsLoading(true);
    try {
      const ids = Object.keys(checkedItems)
        .filter((key) => checkedItems[key])
        .map(Number);

      await TeibanModel.deleteByIds(ids);
      handleUncheckAll();
      reload();
    } catch (error) {}
  };

  /**
   * ドラッグ並び替え処理
   * @returns
   */
  const onDragEnd = (result: any) => {
    if (!result || !result.destination) {
      return;
    }

    const items = Array.from(teibans);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // リストアイテムの並び順を変更する処理

    // ドラッグ終了時に新しい並び順のリストを作成し、適用する
    const updatedTeibans: Teiban[] = items.map((item, index) => ({
      ...item,
      // indexを更新する
      index: index + 1,
    }));

    setTeibans(updatedTeibans);

    const requestPayload = {
      items: updatedTeibans.map((item, index) => ({
        id: item.id,
        order: index + 1, //0は使用しない
      })),
    };
    TeibanModel.updateOrder(requestPayload);
  };

  /**
   * モーダルを開く
   */
  const openDeleteModal = () => {
    setIsShowDeleteModal(true);
  };

  /**
   * モーダルを閉じる
   */
  const closeDeleteModal = () => {
    setIsShowDeleteModal(false);
    setIsShowCopyAllModal(false);
    setIsShowCopyLastMonthModal(false);
  };

  /**
   * 定番から請求に一括コピー
   */
  const onhandleCopyAll = async () => {
    setIsShowCopyAllModal(false);
    setIsLoading(true);
    try {
      await TeibanModel.copyByTeiban({ ym: selected_YM });
      window.reloadFlag = true;
      window.navi.navigate(SCREENS.INVOICE.INVOICE, {});
    } catch (error) {
      setIsLoading(false);
      closeDeleteModal();
      NotificationToast().error("登録に失敗しました。");
    }
  };
  /**
   * 定番から請求に選択したものをコピー
   */
  const onhandleCopySelected = async () => {
    setIsShowCopyAllModal(false);
    setIsLoading(true);
    try {
      await TeibanModel.copyByTeiban({
        teibanIds: checkedIds,
        ym: selected_YM,
      });
      window.reloadFlag = true;
      window.navi.navigate(SCREENS.INVOICE.INVOICE, {});
    } catch (error) {
      setIsLoading(false);
      closeDeleteModal();
      NotificationToast().error("登録に失敗しました。");
    }
  };

  /**
   * 前月請求から請求に一括コピー
   */
  const onhandleCopyLastMonth = async () => {
    setIsShowCopyLastMonthModal(false);
    setIsLoading(true);
    try {
      await TeibanModel.copyLastMonth({ ym: selected_YM, lastYm: lastYM });
      window.reloadFlag = true;
      window.navi.navigate(SCREENS.INVOICE.INVOICE, {});
    } catch (error) {
      setIsLoading(false);

      NotificationToast().error("登録に失敗しました。");
    }
  };

  /**
   * 再読み込み処理
   */
  const reload = () => {
    setIsLoading(true);
    setTeibans([]);
    setPage(0);
    setLastPage(-1);
    loadNext(0, -1);
  };

  /**
   * スクロールイベント
   */
  const onScrollList = (event: React.SyntheticEvent) => {
    const currentTarget = event.currentTarget;

    if (
      currentTarget.scrollHeight >=
      currentTarget.scrollTop + currentTarget.clientHeight + 10
    ) {
      loadNext();
    }
  };

  /**
   * 詳細画面で更新した請求レコードを更新する
   * @param id
   */
  async function _updateItem(id: number) {
    if (teibans) {
      let items: Teiban[] = teibans.slice();
      items.forEach(async (item: Teiban, index: number) => {
        if (item.id == id) {
          const res = await TeibanModel.show(id);
          items[index] = res;
        }
      });
      setTeibans(items);
    }
  }

  /**
   * 詳細画面で削除した請求レコードを除去する
   * @param id
   */
  function _deleteItem(id: number) {
    let items: Teiban[] = teibans.slice();
    for (let i = 0; i < items.length; i++) {
      if (items[i].id == id) {
        items.splice(i, 1);
        break;
      }
    }

    setTeibans(items);
  }
  useEffect(() => {
    if (
      props.route.params &&
      props.route.params.hasOwnProperty("update_id") &&
      props.route.params.update_id > 0
    ) {
      _updateItem(props.route.params.update_id);
    }
    if (
      props.route.params &&
      props.route.params.hasOwnProperty("delete_id") &&
      props.route.params.delete_id > 0
    ) {
      _deleteItem(props.route.params.delete_id);
    }
  }, [props.route.params]);

  useEffect(() => {
    navigation.addListener("focus", async () => {
      window.functions.openFilter = () => {
        navigation.navigate(
          (props.route.params.type ? "Order" : "Invoice") +
            "TeibanFilterScreen",
          {
            target: props.route.params.type ? "Order" : "Invoice",
            backScreen:
              (props.route.params.type ? "Order" : "Invoice") +
              "TeibanSelectScreen",
          }
        );
      };
      window.functions.onPressButton01 = () => {
        setIsShowCopyLastMonthModal(true);
      };
      window.functions.onPressButton02 = () => {
        setIsShowCopyAllModal(true);
      };
      window.functions.reloadList = () => {
        reload();
      };
      if (window.reloadFlag) {
        window.reloadFlag = false;
        reload();
      }
    });

    loadNext();
  }, []);

  /**
   * 月変更ボタン
   * @returns
   */
  const renderChangeMonthBtn = () => {
    return (
      <View style={{ marginBottom: 15 }}>
        <FreeSelectMonth
          selectedYM={selected_YM}
          isNextMonthButton={true}
          onChange={(v) => setSelected_YM(v)}
        ></FreeSelectMonth>
      </View>
    );
  };
  /**
   * 定番のリストアイテム
   * @returns
   */
  const DraggableListItem = ({ item, index }: any) => {
    return (
      <Draggable
        draggableId={item.id.toString()}
        index={index}
        key={item.id.toString()}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <TeibanListItem
              item={item}
              isChecked={checkedItems[item.id] || false}
              onCheck={handleCheckboxToggle}
              onPress={() => {
                window.navi.navigate(SCREENS.INVOICE.INVOICE_TEIBAN_DETAIL, {
                  id: item.id,
                });
              }}
              onUpdated={onUpdated}
            />
          </div>
        )}
      </Draggable>
    );
  };

  const onUpdated = (item: Teiban) => {
    const updatedTeibans = teibans.map((teiban) => {
      if (teiban.id === item.id) {
        teiban.targetday = item.targetday;
        teiban.quantity = item.quantity;
      }
      return teiban;
    });
    setTeibans(updatedTeibans);
  };

  return (
    <View style={styles.container}>
      <HeaderTitleBar title="定番" />
      <Row style={{ width: "100%" }}>
        <Col xs={9} sm={10}></Col> {/* 空のColコンポーネントを追加 */}
        <Col
          xs={3}
          sm={2}
          className="d-flex align-items-center justify-content-end p-2"
        >
          <TouchableOpacity
            style={{
              marginRight: 10,
              paddingHorizontal: 8,
              paddingVertical: 2,
              backgroundColor: CommonColor.Normal.Grey,
              borderRadius: 20,
            }}
            onPress={handleCheckAllItems}
          >
            <Text>全て選択</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              marginRight: 0,
              paddingHorizontal: 8,
              paddingVertical: 2,
              backgroundColor: CommonColor.Normal.Grey,
              borderRadius: 20,
            }}
            onPress={handleUncheckAll}
          >
            <Text>選択解除</Text>
          </TouchableOpacity>
        </Col>
      </Row>
      {teibans && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  width: "100%",
                  overflow: "auto",
                  paddingBottom: 100,
                }} // スクロール可能にするためのスタイルを追加
                onScroll={onScrollList}
              >
                {teibans.map((item: Teiban, index: number) => (
                  <DraggableListItem key={item.id} item={item} index={index} />
                ))}
                {provided.placeholder}
                {isBottomLoading && !isLoading && <CommonLoadingIndicator />}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <FloatingButton
        text={"決定"}
        style={{
          right: 100,
          backgroundColor: CommonColor.Normal.Green,
        }}
        onPress={() => setIsShowCopyAllModal(true)}
      />
      {hasChecked ? (
        <FloatingButton
          icon="trash"
          style={{
            backgroundColor: CommonColor.Normal.Red,
          }}
          onPress={openDeleteModal}
        />
      ) : (
        <FloatingButton
          icon="plus"
          onPress={() => {
            window.navi.navigate(SCREENS.INVOICE.INVOICE_TEIBAN_DETAIL, {
              type: 0,
            });
          }}
        />
      )}

      <CommonModal
        isShow={isShowDeleteModal}
        body="選択済みの項目を削除しますか？"
        actionTitle="削除する"
        onPress={deleteTeiban}
        onDismiss={closeDeleteModal}
      />
      <CommonModal
        isShow={isShowCopyAllModal}
        body={
          hasChecked
            ? `選択した定番を【${displayToYearMonth(
                selected_YM
              )}】の請求にコピーしますか？`
            : `全ての定番を一括で【${displayToYearMonth(
                selected_YM
              )}】の請求にコピーしますか？`
        }
        element={renderChangeMonthBtn()}
        actionTitle="決定"
        onPress={hasChecked ? onhandleCopySelected : onhandleCopyAll}
        onDismiss={closeDeleteModal}
      />
      <CommonModal
        isShow={isShowCopyLastMonthModal}
        body={`${displayToYearMonth(lastYM)}の請求を【 ${displayToYearMonth(
          selected_YM
        )}】 の請求へコピーしますか？`}
        element={renderChangeMonthBtn()}
        actionTitle="決定"
        onPress={onhandleCopyLastMonth}
        onDismiss={closeDeleteModal}
      />
      {isLoading ? <CoverIndicator /> : <></>}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    // flex: 1,
    // justifyContent: "center",
    backgroundColor: "#fff",
  },
  mr10: {
    marginRight: 10,
  },
});
