import "setimmediate";
import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import Storage from "react-native-storage";
import AsyncStorage from "@react-native-community/async-storage";
import axios, { AxiosStatic } from "axios";
import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";
import { useWindowDimensions } from "react-native";
import { ToastContainer } from "react-toastify";
import { Auth0Setting, baseURL } from "./constants/Setting";
import "./assets/styles/common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import CommonClock from "./components/common/CommonClock";
import { User } from "./models/UserModel";
import { Organisations } from "./models/OrganisationsModel";

import {
  LOCAL_STORAGE_KEY,
  LocalStorageKey,
  OrganisationsFilterStorage,
  OrganisationsFilterStorageCompany,
} from "./models/LocalStorage";
import { loadOrgLocalStorage } from "./util/OrgUtil";

/**
 * 認証タイプ
 */
export const AuthType = {
  /**0:OFF */
  laravel: 1,
  /**1:ON*/
  auth0: 2,
};

export type AuthType = (typeof AuthType)[keyof typeof AuthType];

export type ScreenProps = {
  navigation: any;
  functions: any;
};

declare global {
  interface Window {
    width: number;
    height: number;
    axios: AxiosStatic;
    baseUrl: string;
    storage: any;
    accessToken: string | undefined;
    auth0AccessToken: string | undefined;
    navi: any;
    functions: {
      setIsAuth: any;
      isAuth: any;
      openDrawer: any;
      openFilter: any;
      reloadList: any;
      teibanAtOnce: any;
      logout: any;
      openSalesTarget: any;
      unset: any;
      setMonthFilter: any;
      setConfChecked: any;
      setConfFilter: any;
      onPressButton01: any;
      onPressButton02: any;
    };
    authType: AuthType;
    /**セグメント用部門一覧*/
    companyOrganisations: Organisations[];
    /**フィルタ表示用の所属部門 */
    myOrganisations: Organisations[];
    /**自分で選択した部門 */
    selectedMyOrganisationsHome: OrganisationsFilterStorageCompany[];
    selectedMyOrganisationsInvoice: OrganisationsFilterStorageCompany[];
    selectedMyOrganisationsRecipt: OrganisationsFilterStorageCompany[];
    selectedMyOrganisationsCompare: OrganisationsFilterStorageCompany[];
    selectedMyOrganisationsList: OrganisationsFilterStorageCompany[];
    selectedMyOrganisationsDB: OrganisationsFilterStorageCompany[];

    teibanSelectMonth: string;

    commentList: any;
    reloadFlag: boolean;
    reloadFlagWorkTypeSmallHistory: boolean;
    /**小分類を変更した場合に請求一覧を更新させるためのフラグ */
    reloadFlagInvoiceList: boolean;
    currentTeibanType: number;
    invoices: any;
    refreshFlag: boolean;
    userInfo: User | undefined;
  }
}

window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.withCredentials = true;

window.baseUrl = baseURL();

window.storage = new Storage({
  storageBackend: AsyncStorage,
  defaultExpires: 1000 * 3600 * 24 * 365,
});
window.accessToken = "";
window.auth0AccessToken = "";
window.functions = {
  setIsAuth: null,
  isAuth,
  openDrawer: null,
  openFilter: null,
  reloadList: null,
  teibanAtOnce: null,
  logout: null,
  unset: null,
  openSalesTarget: null,
  setMonthFilter: null,
  setConfChecked: null,
  setConfFilter: null,
  onPressButton01: null,
  onPressButton02: null,
};
window.commentList = null;
window.reloadFlag = false;
window.reloadFlagWorkTypeSmallHistory = false;
window.currentTeibanType = 0;
window.companyOrganisations = [];
window.refreshFlag = false;
window.reloadFlagInvoiceList = false;

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const [isAuthed, setIsAuthed] = useState(false);
  const { width, height } = useWindowDimensions();
  // const { isLoading, getAccessTokenSilently } = useAuth0();
  /**Auth0の設定値を格納 */
  const auth0Setting = Auth0Setting();
  window.width = width;
  window.height = height;

  window.functions.logout = function () {
    window
      .axios({
        method: "post",
        url: window.baseUrl + "/logout",
        headers: { Authorization: "Bearer " + window.accessToken },
      })
      .then((res: any) => {
        const { data } = res;
        if (!data.result) {
          alert("ログアウトに失敗しました");
        } else {
          window.storage.remove({
            key: LOCAL_STORAGE_KEY.ACCESS_TOKEN,
          });
          window.accessToken = "";
          window.userInfo = undefined;
          window.functions.setIsAuth(false);
        }
      })
      .catch((err: any) => {
        window.storage.remove({
          key: LOCAL_STORAGE_KEY.ACCESS_TOKEN,
        });
        window.accessToken = "";
        window.userInfo = undefined;
        window.functions.setIsAuth(false);
      });
  };

  // // 全画面表示に切り替える関数
  // const enterFullScreen = () => {
  //   if (document.documentElement.requestFullscreen) {
  //     document.documentElement.requestFullscreen();
  //   } else if (document.documentElement.mozRequestFullScreen) {
  //     document.documentElement.mozRequestFullScreen();
  //   } else if (document.documentElement.webkitRequestFullscreen) {
  //     document.documentElement.webkitRequestFullscreen();
  //   } else if (document.documentElement.msRequestFullscreen) {
  //     document.documentElement.msRequestFullscreen();
  //   }
  //   setIsFullScreen(true);
  // };

  // // 全画面表示を終了する関数
  // const exitFullScreen = () => {
  //   if (document.exitFullscreen) {
  //     document.exitFullscreen();
  //   } else if (document.mozCancelFullScreen) {
  //     document.mozCancelFullScreen();
  //   } else if (document.webkitExitFullscreen) {
  //     document.webkitExitFullscreen();
  //   } else if (document.msExitFullscreen) {
  //     document.msExitFullscreen();
  //   }
  //   setIsFullScreen(false);
  // };

  // useEffect(() => {
  //   const initializeAuth0 = async () => {
  //     setIsAuthed(true);
  //   };

  //   initializeAuth0();
  // }, []);

  // if (!isAuthed) {
  //   // 認証が完了するまで何も表示しない
  //   return null;
  // }

  if (!isLoadingComplete) {
    return null;
  } else {
    localStorage.removeItem(LocalStorageKey.BILLING_DATE);
    return (
      // <Auth0Provider
      //   domain={auth0Setting.domain}
      //   clientId={auth0Setting.clientId}
      //   authorizationParams={{
      //     redirect_uri: window.location.origin,
      //     audience: "https://" + auth0Setting.domain + "/api/v2/",
      //     scope: "read:current_user update:current_user_metadata",
      //   }}
      // >
      <SafeAreaProvider>
        <ToastContainer />
        <CommonClock style={{ paddingLeft: 10 }} />
        <Navigation colorScheme={colorScheme} />
        <StatusBar />
      </SafeAreaProvider>
      // </Auth0Provider>
    );
  }
}

/**
 * 認証状態を確認する。
 * ※laravel認証のトークンを呼び出す。
 *
 * @returns
 */
export async function isAuth(): Promise<string | null> {
  //laravel認証でのログインを検証する。
  return await window.storage
    .load({ key: LOCAL_STORAGE_KEY.ACCESS_TOKEN })
    .catch((err: any) => null);
}

/**
 * 初期値用に保存したLocalStorageを呼び出す。
 */
export async function loadLocalStorageInit() {
  window.selectedMyOrganisationsHome = await loadOrgLocalStorage(
    LocalStorageKey.HOME
  );
  window.selectedMyOrganisationsInvoice = await loadOrgLocalStorage(
    LocalStorageKey.INVOICE
  );
  window.selectedMyOrganisationsRecipt = await loadOrgLocalStorage(
    LocalStorageKey.RECEIPT
  );
  window.selectedMyOrganisationsList = await loadOrgLocalStorage(
    LocalStorageKey.WORK_LIST
  );
  window.selectedMyOrganisationsCompare = await loadOrgLocalStorage(
    LocalStorageKey.WORK_COMPARE
  );
  window.selectedMyOrganisationsDB = await loadOrgLocalStorage(
    LocalStorageKey.WORK_DB
  );
}
