import { OrgFilterType } from "../components/filter/CommonBumonFilter";
import { ApiMethodType, get, post } from "../util/Api";
import { WorkListFilterOption } from "./WorkListFilterModel";

import { WorkType } from "./WorkTypeModel";

export interface TotalList {
  [key: string]: Invoice;
}

/**仕事小分類 */
export const WORK_TYPE_SMALL_DEFAULT_NAME = "(その他)";

/**受領した請求 */
export function isReceived(state: InvoiceStateType) {
  if (state === InvoiceStateType.Received) {
    return true;
  }
  return false;
}

/**
 * 請求フラグ
 */
export const DraftFlg = {
  /**0:OFF */
  OFF: 0,
  /**1:ON*/
  ON: 1,
};

export type DraftFlg = (typeof DraftFlg)[keyof typeof DraftFlg];

/**
 * 請求状態
 */
export const InvoiceStateType = {
  /**0:確認中 */
  Pending: 0,
  /**1:受領 */
  Received: 1,
  /**2:却下 */
  Rejected: 2,
  /**3:保留 */
  Onhold: 3,
  /**4:再提出 */
  Resubmitted: 4,
};

export type InvoiceStateType =
  (typeof InvoiceStateType)[keyof typeof InvoiceStateType];

/**
 * 請求
 */
export interface Invoice {
  //日付
  billing_date: string;
  commented: number;
  company: Company;
  company_id: number;
  company_name: string;
  created_at: Date;
  deleted_at?: Date;
  draft: DraftFlg;
  from_org: number;
  from_org_name_s: string;
  from_org_obj: Org;
  id: number;
  own: boolean;
  quantity: number;
  quantity_type: number;
  receipt_date?: Date;
  recipient_id?: number;
  source_id: number;
  state: InvoiceStateType;
  state_label: string;
  teiban_id: number;
  to_org: number;
  to_org_name: string;
  to_org_name_s: string;
  to_org_obj: Org;
  total: number;
  type: number;
  unit_price: number;
  unread: number;
  updated_at: Date;
  user_id: number;
  work: string;
  work_type?: WorkType;
  work_type_id: number;
  work_type_name: string;
  work_type_obj: WorkType;
  work_type_small_id?: number;
  work_type_small_name?: string;
  work_type_small?: WorkType;
}

export class InvoiceModel {
  static set(data: any): Invoice {
    const d: Invoice = {
      billing_date: data.billing_date,
      commented: data.commented,
      company: data.company,
      company_id: data.company_id,
      company_name: data.company_name,
      created_at: data.created_at,
      deleted_at: data.deleted_at ?? null,
      draft: data.draft,
      from_org: data.from_org,
      from_org_name_s: data.from_org_name_s,
      from_org_obj: data.from_org_obj,
      id: data.id,
      own: data.own,
      quantity: data.quantity,
      quantity_type: data.quantity_type,
      receipt_date: data.receipt_date ?? null,
      recipient_id: data.recipient_id ?? null,
      source_id: data.source_id,
      state: data.state,
      state_label: data.state_label,
      teiban_id: data.teiban_id,
      to_org: data.to_org,
      to_org_name: data.to_org_name,
      to_org_name_s: data.to_org_name_s,
      to_org_obj: data.to_org_obj,
      total: data.total,
      type: data.type,
      unit_price: data.unit_price,
      unread: data.unread,
      updated_at: data.updated_at,
      user_id: data.user_id,
      work: data.work,
      work_type: data.work_type,
      work_type_id: data.work_type_id,
      work_type_name: data.work_type_name,
      work_type_obj: data.work_type_obj,
      work_type_small_id: data.work_type_small_id,
      work_type_small_name: data.work_type_small_obj,
      work_type_small: data.work_type_small,
    };

    return d;
  }

  /**
   * 請求参照
   * @param params
   * @returns invoice
   */
  static async show(id: number): Promise<Invoice> {
    const uri = "/invoice/" + id;
    let response = await get(uri, {});

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return this.set(response.data);
  }

  /**
   * 請求参照(一覧用)
   * @param params
   * @returns invoice
   */
  static async item(id: number): Promise<Invoice> {
    const uri = "/invoice/item";
    let response = await post(uri, { id: id });

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return this.set(response.data);
  }

  /**
   * 請求一覧を取得する
   * @param params
   * @returns
   */
  static async fetchInvoiceList(params: {
    page: number;
    billing_ym: string;
    to_filter_types: number[];
    to_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];
    isAsc?: boolean;
    isOnlyPending?: boolean;
  }): Promise<{
    current_page: number;
    last_page: number;
    total: number;
    data: Invoice[];
  }> {
    const uri = "/invoice";
    let response = await get(uri, params);

    if (response.status !== 200) {
      throw new Error();
    }

    const responseData = response.data;
    const current_page = responseData.current_page;
    const last_page = responseData.last_page;
    const total = responseData.total;

    const teibans = Array.isArray(responseData.data)
      ? responseData.data.map((data: any) => this.set(data))
      : [this.set(responseData.data)];

    return {
      current_page: current_page,
      last_page: last_page,
      data: teibans,
      total: total,
    };
  }

  /**
   * 月間の請求合計を取得する
   * @param params
   * @returns
   */
  static async getTotalByMonth(params: {
    billing_ym: string;
    to_filter_types?: number[];
    to_org_ids?: number[];
    from_filter_types?: number[];
    from_org_ids?: number[];
    states?: number[];
  }): Promise<{ total: number; total_k: number }> {
    const uri = "/invoice/totalByYearMonth";
    let response = await post(uri, params);

    if (response.status !== 200) {
      throw new Error();
    }

    const data = response.data;

    if (data === null || data === undefined) {
      return { total: 0, total_k: 0 };
    }

    return { total: data.total, total_k: data.total_k };
  }

  /**
   * 仕事(内容)の履歴を取得する
   * @param params
   * @returns
   */
  static async fetchWorkHistory(params: {
    to_org_id: number;
    from_org_id: number;
    work_type_id: number;
    work_type_small_id?: number;
  }): Promise<{ name: string }[]> {
    const uri = "/work/workHistory";
    let response = await post(uri, {
      to_org_id: params.to_org_id,
      from_org_id: params.from_org_id,
      work_type_id: params.work_type_id,
      work_type_small_id:
        params.work_type_small_id === 0 ? null : params.work_type_small_id,
    });

    if (response.status !== 200) {
      throw new Error();
    }

    const data = response.data as Array<string>;

    if (data === null || data === undefined) {
      return [];
    }

    return data.map((e: string) => {
      return { name: e };
    });
  }

  /**
   * 仕事(内容)の履歴を取得する
   * @param params
   * @returns
   */
  static async fetchUnitPriceHistory(params: {
    to_org_id: number;
    from_org_id: number;
    work_type_id: number;
    work_type_small_id?: number;
    work?: string;
  }): Promise<{ name: string }[]> {
    const uri = "/work/unitPriceHistory";
    let response = await post(uri, {
      to_org_id: params.to_org_id,
      from_org_id: params.from_org_id,
      work_type_id: params.work_type_id,

      work_type_small_id:
        params.work_type_small_id === 0 ? null : params.work_type_small_id,
      work: params.work,
    });

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    const data = response.data as Array<string>;

    if (data === null || data === undefined) {
      return [];
    }

    return data.map((e: string) => {
      return { name: e };
    });
  }

  /**
   * 複数のIDを指定し一括削除する
   * @param params
   * @returns
   */
  static async deleteByIds(ids: number[]): Promise<{}> {
    const uri = "/invoice/deleteByIds";
    let response = await post(uri, { ids: ids });
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    const data = response.data;
    return {};
  }

  /**
   * 複数のIDを指定し一括で状態を変更する
   * @param params
   * @returns
   */
  static async changeStateByIds(params: {
    ids: number[];
    state: InvoiceStateType;
  }): Promise<{}> {
    const uri = "/receipt/changeStateByIds";
    let response = await post(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    const data = response.data;
    return {};
  }

  /**
   * 請求前後のID
   * @param params
   * @returns
   */
  static async previousAndNextId(params: {
    id: number;
    billing_ym: string;
    to_filter_types: number[];
    to_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];
  }): Promise<{
    previousId?: number;
    nextId?: number;
  }> {
    const uri = "/invoice/getPreviousAndNext";
    let response = await post(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    const data = response.data;
    return {
      previousId: data.previousId,
      nextId: data.nextId,
    };
  }

  /**
   * 新規作成
   * @param params
   */
  static async create(params: {
    billing_date: Date;
    to_org: number;
    from_org: number;
    work_type_id: number;
    work_type_small_id: number;
    work_type_small_name: string;
    work: string;
    unit_price: string;
    quantity: string;
    total: string;
    state: 0;
    comment: any;
    draft: number;
    teiban_id?: number;
  }): Promise<void> {
    const uri = "/invoice";

    const response = await post(uri, params);

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return response.data;
  }

  /**
   * 更新する
   * @param params
   * @returns
   */
  static async update(params: {
    id: number;
    billing_date: Date;
    to_org: number;
    from_org: number;
    work_type_id: number;
    work_type_small_id: number;
    work_type_small_name: string;
    work: string;
    unit_price: string;
    quantity: string;
    total: string;
    state: InvoiceStateType;
    comment: any;
    draft: DraftFlg;
    teiban_id?: number;
  }): Promise<void> {
    const uri = "/invoice/" + params.id;

    const response = await post(uri, params, ApiMethodType.PUT);

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return response.data;
  }
  /**
   * 最新の請求を取得
   * @param params
   */
  static async last(params?: {
    to_org_id: number;
    from_org_id: number;
  }): Promise<Invoice | null> {
    const uri = "/invoice/last";

    const response = await post(uri, params);

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    if (response.data == null) {
      return null;
    }

    return this.set(response.data);
  }

  /*********************************************
   * 受領関連
   *********************************************/
  /**
   * 受領一覧を取得する
   * @param params
   * @returns
   */
  static async fetchReceiptList(params: {
    page: number;
    billing_ym: string;
    to_filter_types: number[];
    to_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];
    states: number[];
    isAsc?: boolean;
  }): Promise<{
    current_page: number;
    last_page: number;
    total: number;
    data: Invoice[];
  }> {
    const uri = "/receipt";
    let response = await get(uri, params);

    if (response.status !== 200) {
      throw new Error();
    }

    const responseData = response.data;
    const current_page = responseData.current_page;
    const last_page = responseData.last_page;
    const total = responseData.total;

    const receipts = Array.isArray(responseData.data)
      ? responseData.data.map((data: any) => this.set(data))
      : [this.set(responseData.data)];

    return {
      current_page: current_page,
      last_page: last_page,
      data: receipts,
      total: total,
    };
  }

  /**
   * 請求(受領)参照
   * @param params
   * @returns invoice
   */
  static async receiptShow(id: number): Promise<Invoice> {
    const uri = "/receipt/" + id;
    let response = await get(uri, {});

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return this.set(response.data);
  }

  /**
   * 請求(受領)参照(一覧用)
   * @param params
   * @returns invoice
   */
  static async receiptItem(id: number): Promise<Invoice> {
    const uri = "/receipt/item";
    let response = await post(uri, { id: id });

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return this.set(response.data);
  }

  /**
   * 更新する(受領)
   * @param params
   * @returns
   */
  static async updateReceipt(params: {
    id: number;
    billing_date: Date;
    to_org: number;
    from_org: number;
    work_type_id: number;
    work_type_small_id: number;
    work_type_small_name: string;
    work: string;
    unit_price: string;
    quantity: string;
    total: string;
    state: InvoiceStateType;
  }): Promise<void> {
    const uri = "/receipt/" + params.id;

    const response = await post(uri, params, ApiMethodType.PUT);

    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }

    return response.data;
  }

  /**
   * 一括で状態を変更する
   * @param params
   * @returns
   */
  static async changeStateByFilter(params: {
    billing_ym: string;
    to_filter_types: number[];
    to_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];
    states: number[];
    changeState: number;
  }): Promise<{}> {
    const uri = "/receipt/changeStateByFilter";
    let response = await post(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    const data = response.data;
    return {};
  }

  /**
   * 受領前後のID
   * @param params
   * @returns
   */
  static async previousAndNextIdReceipt(params: {
    id: number;
    billing_ym: string;
    to_filter_types: number[];
    to_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];

    states: number[];
  }): Promise<{
    previousId?: number;
    nextId?: number;
  }> {
    const uri = "/receipt/getPreviousAndNext";
    let response = await post(uri, params);
    if (response.status !== 200) {
      window.functions.logout;
      throw new Error();
    }
    const data = response.data;
    return {
      previousId: data.previousId,
      nextId: data.nextId,
    };
  }

  /*********************************************
   * 仕事一覧画面
   *********************************************/

  /**
   * 受領一覧を取得する
   * @param params
   * @returns
   */
  static async fetchWorkList(params: {
    page: number;
    billing_ym: string;
    to_filter_types: number[];
    to_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];
    states: number[];
    filter: WorkListFilterOption;
    sort: string;
  }): Promise<{
    current_page: number;
    last_page: number;
    total: number;
    data: Invoice[];
  }> {
    const uri = "/workList/list";
    let response = await post(uri, params);

    if (response.status !== 200) {
      throw new Error();
    }

    const responseData = response.data;
    const current_page = responseData.current_page;
    const last_page = responseData.last_page;
    const total = responseData.total;

    const receipts = Array.isArray(responseData.data)
      ? responseData.data.map((data: any) => this.set(data))
      : [this.set(responseData.data)];

    return {
      current_page: current_page,
      last_page: last_page,
      data: receipts,
      total: total,
    };
  }

  /**
   * 合計値を取得する
   * @param params
   * @returns
   */
  static async fetchWorkListTotal(params: {
    billing_ym: string;
    to_filter_types: number[];
    to_org_ids: number[];
    from_filter_types: number[];
    from_org_ids: number[];
    states: number[];
    filter: WorkListFilterOption;
  }): Promise<{ total: number; total_kakunin: number }> {
    const uri = "/workList/total";
    let response = await post(uri, params);

    if (response.status !== 200) {
      throw new Error();
    }

    const responseData = response.data ?? 0;

    return responseData;
  }

  static async fetchRetrieveUnreceivedAmount() {
    const uri = "/receipt/retrieveUnreceivedAmount";
    let response = await post(uri);

    if (response.status !== 200) {
      throw new Error();
    }
    const responseData = response.data ?? 0;

    return responseData;
  }
}
