import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FlatList, View, TouchableOpacity } from "react-native";
import { Divider, Text } from "react-native-elements";
import { WorkMemos, WorkMemosModel } from "../../models/WorkMemosModel";
import { convertToMoneyAndFormat } from "../../util/Math";
import {
  WorkMemoRequests,
  WorkMemoRequestsModel,
} from "../../models/WorkMemoRequestsModel";

type WorkMemoRequestModalProps = {
  isShow: boolean;
  workName?: string;
  workTypeId?: number;
  workMemoId?: number;
  onDismiss?: () => void;
  onLoaded?: (e: WorkMemoRequests | null) => void;
};

export function WorkMemoRequestModal(props: WorkMemoRequestModalProps) {
  const [workMemoRequest, setWorkMemoRequest] = useState<
    WorkMemoRequests | undefined
  >();
  const [comment, setComment] = useState<string>("");

  const fetch = async (params: {
    workMemoId?: number;
    workTypeId?: number;
  }) => {
    const res = await WorkMemoRequestsModel.find(params);
    console.log(res);

    setWorkMemoRequest(res ?? undefined);
    setComment(res?.comment ?? "");
    if (props.onLoaded) {
      props.onLoaded(res);
    }
  };

  const handleSave = async () => {
    const res = await WorkMemoRequestsModel.upsert({
      id: workMemoRequest?.id,
      workMemoId: props?.workMemoId,
      workTypeId: props?.workTypeId,
      comment: comment,
    });

    setWorkMemoRequest(res ?? undefined);
    setComment(res?.comment ?? "");
    if (props.onLoaded) {
      props.onLoaded(res);
    }

    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  const handleDelete = async () => {
    if (workMemoRequest?.id == undefined) return;

    const res = WorkMemoRequestsModel.delete({ id: workMemoRequest.id });
    setWorkMemoRequest(undefined);
    setComment("");

    if (props.onLoaded) {
      props.onLoaded(null);
    }

    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  useEffect(() => {
    if (props.workTypeId == undefined || props.workTypeId == 0) return;
    fetch({ workTypeId: props.workTypeId });
  }, [props.workTypeId]);

  useEffect(() => {
    if (props.workMemoId == undefined || props.workMemoId == 0) return;
    fetch({ workMemoId: props.workMemoId });
  }, [props.workMemoId]);

  return (
    <>
      <Modal
        show={props.isShow}
        onHide={props?.onDismiss}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"仕事(メモ)要望"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "50vh", overflowY: "auto" }}>
          <Form>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>{`要望 ${props?.workName ?? ""}`}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSave}>登録</Button>
          {workMemoRequest?.id && workMemoRequest.id !== undefined && (
            <Button variant="danger" onClick={handleDelete}>
              削除
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
